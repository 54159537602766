import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function CustomAccordion({ title, text, component, expanded }) {
	return (
		<Accordion
			defaultExpanded={expanded ? true : false}
			slotProps={{ transition: { unmountOnExit: true } }}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls='usernamePanelbh-content'
			>
				<Typography id='typographySettings'>{title}</Typography>
			</AccordionSummary>
			{/* <hr className='mg0 ' /> */}
			<AccordionDetails>
				<p className='mgtH'>{text}</p>
				{component}
			</AccordionDetails>
		</Accordion>
	);
}
