import { Component } from 'react';
import { connect } from 'react-redux';
import {
	setSpammedUserAdmin,
	updateUserFields,
	updateUserUid,
	revokeAllTokenSessionsAdmin,
	logoutAllSessionsAdmin,
	adminResetImage,
	setAdminPriviledge,
	reduceUserDetailsAdmin,
} from '../../redux/actions/userActions';
import {
	isEmail,
	noImgUrl,
	getAccountReports,
	isEmailVerifiedAdmin,
} from '../../redux/actions/dataActions';
import { setAdminUserListener } from '../../firebase/firebaseInit';
import PropTypes from 'prop-types';
import {
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material';
import Switch from '../../components/SocialComponents/Switch';
import CustomInput from '../../components/SocialComponents/CustomInput';
import dayjs from 'dayjs';
import CustomButton from '../../components/SocialComponents/CustomButton';
import Searchbar from '../../components/SocialComponents/Searchbar';
import ImgSrc from '../../components/SocialComponents/ImgSrc';
import CopyText from '../../components/SocialComponents/CopyText';
import ConfirmationDialog from '../../components/SocialComponents/ConfirmationDialog';
import DottedMenu from '../../components/SocialComponents/DottedMenu';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import MailIcon from '@mui/icons-material/Mail';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AccountData from './AccountData';
import AccountReports from './AccountReports';
import CustomPopover from '../../components/CustomPopover/CustomPopover';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';
import TimerCountdown from '../../components/PostTimer/TimerCountdown';
import { t } from 'i18next';

class AdminActions extends Component {
	state = {
		clearDisabled: false,
		querySearchbar: false,
		userId: '',
		errors: {},
		spammedCount: 0,
		disabledFor: '',
		emailErrors: {},
		showTable: true,
		showActions: true,
		revokeConfirmationDialog: false,
		logoutConfirmationDialog: false,
		setAdminConfirmationDialog: false,
		disabledForConfirmationDialog: false,
		removeConfirmationDialog: false,
		removeDetailConfirmationDialog: false,
		collection: '',
		detail: '',
		field: 'followerCount',
		emailVerified: '',
		email: '',
		isEmailReporter: false,
	};

	componentDidMount() {
		if (this.props.userId) {
			let update = {
				userId: this.props.userId,
			};

			if (this.props.reportDialog) {
				this.setState({
					showTable: false,
					showActions: false,
				});
			}
			if (isEmail(this.props.userId)) {
				update.isEmail = true;
				this.props.getAccountReports({
					userId: this.props.userId,
					isEmail: true,
				});
			} else {
				this.props.setAdminUserListener(this.props.userId);
				this.props.getAccountReports({ userId: this.props.userId });
			}

			this.setState(update);
		} else if (this.props.data.setUserAdmin.userId) {
			this.props.setAdminUserListener(this.props.data.setUserAdmin.userId);
		}
	}

	showMore = (section) => {
		this.setState((prevState) => ({
			[section]: !prevState[section],
		}));
	};
	componentWillUnmount() {
		if (this.props.data.setUserAdmin.userId) {
			this.props.setAdminUserListener(this.props.data.setUserAdmin.userId);
		}
	}

	handleConfirmationDialog = (boolean, action) => {
		if (action === 'removeImg') {
			this.setState({ removeConfirmationDialog: boolean });
		} else if (action === 'disabledFor') {
			this.setState({ disabledForConfirmationDialog: boolean });
		} else if (action === 'revokeToken') {
			this.setState({ revokeConfirmationDialog: boolean });
		} else if (action === 'logoutAllSessions') {
			this.setState({ logoutConfirmationDialog: boolean });
		} else if (action === 'setAdmin') {
			this.setState({ setAdminConfirmationDialog: boolean });
		} else if (action === 'removeDetail') {
			this.setState({ removeDetailConfirmationDialog: boolean });
		}
	};

	handleSwitch = (e, query) => {
		if (query === 'querySearchbar') {
			this.setState((prevState) => ({
				querySearchbar: !prevState.querySearchbar,
			}));
		} else {
			this.setState((prevState) => ({
				clearDisabled: !prevState.clearDisabled,
				spammedCount: 0,
			}));
		}
	};
	handleChange = (e) => {
		e.preventDefault();
		this.setState({
			[e.target.name]: e.target.value,
		});
		if (e.target.name === 'spammedCount') {
			this.calculateDisabledFor(e.target.value);
			if (
				!this.state.clearDisabled &&
				e.target.value === 0 &&
				this.props.data.setUserAdmin.disabledFor > new Date().toISOString()
			) {
				this.setState({
					clearDisabled: true,
				});
			} else if (e.target.value >= 1 && this.state.clearDisabled) {
				this.setState({
					clearDisabled: false,
				});
			}
		}
	};

	calculateDisabledFor = (spammedCount) => {
		let futureDate = new Date();
		// Set the initial duration based on spammedCount
		let durationInHours;
		// Adjust the duration based on spammedCount

		if (spammedCount === 0) {
			durationInHours = spammedCount;
		} else if (spammedCount === 1) {
			// If spammedCount is 2, disable for a day
			durationInHours = spammedCount * 3;
		} else if (spammedCount === 2) {
			// If spammedCount is 2, disable for a day
			durationInHours = 24;
		} else if (spammedCount === 3) {
			// If spammedCount is 3, disable for a week
			durationInHours = 24 * 7;
		} else if (spammedCount === 4) {
			// If spammedCount is 4, disable for a month
			// Note: This is a simple approximation; it doesn't consider varying month lengths
			durationInHours = 24 * 30;
		} else if (spammedCount === 5) {
			// If spammedCount is 5, disable for 5 months
			durationInHours = 24 * 30 * 5;
		} else if (spammedCount === 6) {
			// If spammedCount is 6, disable for a year
			durationInHours = 24 * 365;
		} else if (spammedCount > 6) {
			durationInHours = (spammedCount / 3) * 24 * 365;
		}

		// Calculate the future date based on the adjusted duration
		futureDate.setHours(futureDate.getHours() + durationInHours);
		this.setState({
			disabledFor: futureDate.toISOString(),
		});
	};
	query = () => {
		const userId = this.state.userId;

		this.props.setAdminUserListener(userId);
		this.props.getAccountReports({ userId });
		this.props.isEmailVerifiedAdmin(userId);
	};

	setRecipient = (recipient) => {
		const userId = recipient.userId;
		this.setState({
			userId,
			querySearchbar: false,
		});
		this.props.setAdminUserListener(userId);
		this.props.getAccountReports({ userId });
		this.props.isEmailVerifiedAdmin(userId);
	};
	updateUser = () => {
		let formData = {
			spammedCount: this.state.spammedCount,
			calculateCount: this.state.spammedCount,
			clearDisabled: this.state.clearDisabled,
			disabledFor: this.state.disabledFor,
			userId: this.props.data.setUserAdmin.userId,
		};

		this.setState({ disabledForConfirmationDialog: false });

		this.props.setSpammedUserAdmin(formData);
	};
	removeImg = () => {
		let formData = {
			userId: this.props.data.setUserAdmin.userId,
		};
		this.setState({
			removeConfirmationDialog: false,
		});
		this.props.adminResetImage(formData);
	};

	updateUserFields = () => {
		let formData = {
			userId: this.props.data.setUserAdmin.userId,
		};
		if (this.state.collection !== '') {
			formData.collection = this.state.collection;
		}
		if (this.state.field !== '') {
			formData.field = this.state.field;
		}
		this.props.updateUserFields(formData);
	};

	testEmail = () => {
		let isEmailBool = isEmail(this.state.email);

		if (!isEmailBool) {
			this.setState({
				emailErrors: {
					email: t('email_error_enter_address'),
				},
			});
		} else {
			this.setState({
				emailErrors: { email: '' },
			});
			return true;
		}
	};
	updateUID = () => {
		let formData = {
			userId: this.props.data.setUserAdmin.userId,
		};
		if (this.state.emailVerified !== '') {
			formData.emailVerified =
				this.state.emailVerified === 'true' ? true : false;
		}
		if (this.state.email !== '' && this.testEmail()) {
			formData.newEmail = this.state.email;
		}
		if (Object.keys(formData).length >= 2) {
			this.props.updateUserUid(formData);
		}
	};
	setAdmin = () => {
		let userId = this.props.data.setUserAdmin.userId;
		let addAdmin = false;
		if (!this.props.data.setUserAdmin.admin) {
			addAdmin = true;
		}
		let formData = {
			userId,
			addAdmin,
		};
		this.props.setAdminPriviledge(formData);
		this.setState({ setAdminConfirmationDialog: false });
	};
	handleChangeMail = (event) => {
		if (event.target.name === 'email') {
			this.setState({
				[event.target.name]: event.target.value,
			});
		}
	};

	reduceUserDetails = () => {
		let userId = this.props.data.setUserAdmin.userId;

		let formData = {
			userId,
			key: this.state.detail,
		};
		this.setState({
			removeDetailConfirmationDialog: false,
		});
		this.props.reduceUserDetailsAdmin(formData);
	};
	revoke = (action) => {
		if (this.props.data.setUserAdmin.userId) {
			if (action === 'token') {
				this.props.revokeAllTokenSessionsAdmin({
					userId: this.props.data.setUserAdmin.userId,
				});
				this.setState({ revokeConfirmationDialog: false });
			} else {
				this.props.logoutAllSessionsAdmin({
					userId: this.props.data.setUserAdmin.userId,
				});
				this.setState({ logoutConfirmationDialog: false });
			}
		}
	};
	render() {
		const {
			data: { setUserAdmin, loadingAccountReports },
			UI: { mode },
			userQueried,
			reportDialog,
		} = this.props;
		const {
			clearDisabled,
			userId,
			errors,
			spammedCount,
			disabledFor,
			querySearchbar,
			revokeConfirmationDialog,
			logoutConfirmationDialog,
			setAdminConfirmationDialog,
			disabledForConfirmationDialog,
			removeConfirmationDialog,
			removeDetailConfirmationDialog,
			collection,
			detail,
			field,
			emailVerified,
			emailErrors,
			email,
			isEmailReporter,
			showTable,
			showActions,
		} = this.state;

		return (
			<div className={`${mode === 'dark' && 'dbc'}`}>
				<div className='flex spaceAround alignCenter mgb'>
					{!userQueried && (
						<FormControlLabel
							control={
								<Switch
									value='querySearchbar'
									handleSwitchChange={(e) =>
										this.handleSwitch(e, 'querySearchbar')
									}
									checked={querySearchbar}
									name='querySearchbar'
								/>
							}
							label='Query User Id'
							labelPlacement='bottom'
						/>
					)}
					{Object.keys(setUserAdmin).length > 0 && (
						<div className='flexColumn alignCenter'>
							<ImgSrc
								img={setUserAdmin.imageUrl}
								thumbnail={setUserAdmin.thumbnail}
								handle={setUserAdmin.handle}
								imgColor={setUserAdmin.imageColor}
								css='listImg'
								fontSize='25px'
								noMargin
							/>
							<p className='bold mg0 mgtH'>{setUserAdmin.handle}</p>
						</div>
					)}
					{isEmailReporter && (
						<CustomAlerts
							info={isEmailReporter}
							message={
								<CopyText frase={<b>Reporter:</b>} text={userId} noMargin />
							}
							margin='0.5rem 0'
							icon={<MailIcon />}
							variant='outlined'
							// noCenter
						/>
					)}
					{reportDialog && !isEmailReporter && (
						<FormControlLabel
							control={
								<Switch
									value='showTable'
									handleSwitchChange={() => this.showMore('showTable')}
									checked={showTable}
									name='showTable'
								/>
							}
							label={showTable ? 'Hide details' : 'Show details'}
							labelPlacement='bottom'
						/>
					)}

					{Object.values(setUserAdmin).length > 0 && reportDialog && (
						<FormControlLabel
							control={
								<Switch
									value='showActions'
									handleSwitchChange={() => this.showMore('showActions')}
									checked={showActions}
									name='showActions'
								/>
							}
							label={showActions ? 'Hide actions' : 'Show actions'}
							labelPlacement='bottom'
						/>
					)}
				</div>
				{!userQueried && querySearchbar && (
					<div
						id='scrollableAdminDiv'
						style={{
							overflow: 'auto',
							height: '21vh',
							// height: 'auto',
							minHeight: '100px',
						}}
					>
						<Searchbar
							adminFunctionality={true}
							setRecipient={this.setRecipient}
						/>
					</div>
				)}
				{!userQueried && (
					<div className='pdb'>
						<CustomInput
							variant='outlined'
							titleLabel='User ID'
							placeholder='Enter a userId'
							name='userId'
							inputId='userId'
							autoComplete='userId'
							onChange={this.handleChange}
							helperText={errors.userId}
							inputError={errors.userId ? true : false}
							inputValue={userId}
							maxLength={50}
							fullWidth={true}
						/>
					</div>
				)}
				{!userQueried && userId !== '' && (
					<CustomButton
						btnText={t('search')}
						variant='contained'
						id='customInfoButton'
						onClick={this.query}
						loading={loadingAccountReports}
					/>
				)}
				{Object.values(setUserAdmin).length > 0 && (
					<div>
						{showTable && <AccountData />}
						{!reportDialog && <AccountReports queryUser={true} />}

						{showActions && (
							<div>
								<hr />
								<hr />
								{setUserAdmin.imageUrl !== noImgUrl() && (
									<ConfirmationDialog
										open={removeConfirmationDialog}
										openIconOrString='Delete Profile Image'
										idOpenIconOrString='customCancelButton'
										customButtonId='customDangerButton'
										closeFunc={() =>
											this.handleConfirmationDialog(false, 'removeImg')
										}
										openFunc={() =>
											this.handleConfirmationDialog(true, 'removeImg')
										}
										actionBtnText='Remove Picture'
										action={this.removeImg}
										startIcon={<DeleteOutlineIcon id='iconButton' />}
										phrase='Delete Profile Image'
										contentTitle={`Are you shoure you want to delete ${setUserAdmin.handle}'s profile picture?`}
										phrase2="This action can't be undone."
										component={
											<div className='pdY'>
												<ImgSrc
													img={setUserAdmin.imageUrl}
													thumbnail={setUserAdmin.thumbnail}
													handle={setUserAdmin.handle}
													imgColor={setUserAdmin.imageColor}
													css='imgProfile'
													fontSize='4vw'
												/>
											</div>
										}
										backgroundColor='#ff3838'
									/>
								)}
								<hr />
								<hr />
								<label className='inputText mgt mgb3px'>Remove Details</label>
								<FormControl fullWidth>
									<InputLabel id='demo-simple-select-label'>Detail</InputLabel>
									<Select
										value={detail}
										name='detail'
										label='Detail'
										onChange={this.handleChange}
									>
										{setUserAdmin.name && (
											<MenuItem value='name'>Name</MenuItem>
										)}
										{setUserAdmin.location && (
											<MenuItem value='location'>Location</MenuItem>
										)}
										{setUserAdmin.biography && (
											<MenuItem value='biography'>Biography</MenuItem>
										)}
										{setUserAdmin.quote && (
											<MenuItem value='quote'>Quote</MenuItem>
										)}
									</Select>
								</FormControl>
								{detail !== '' && (
									<ConfirmationDialog
										open={removeDetailConfirmationDialog}
										openIconOrString={t('update')}
										idOpenIconOrString='customCancelButton'
										customButtonId='customDangerButton'
										closeFunc={() =>
											this.handleConfirmationDialog(false, 'removeDetail')
										}
										initialBtnIcon={<AccountCircleRoundedIcon />}
										openFunc={() =>
											this.handleConfirmationDialog(true, 'removeDetail')
										}
										actionBtnText='Remove Detail'
										action={this.reduceUserDetails}
										startIcon={<DeleteOutlineIcon id='iconButton' />}
										phrase='Remove Details'
										contentTitle={`Are you shoure you want to delete ${setUserAdmin.handle}'s ${detail}?`}
										phrase2="This action can't be undone."
										phrase4={setUserAdmin[detail]}
										backgroundColor='#ff3838'
									/>
								)}
								<hr />
								<hr />
								<label className='inputText mgt mgb3px'>
									Update fields incase of wrong count
								</label>

								<FormControl fullWidth>
									<InputLabel id='demo-simple-select-label'>
										Collection
									</InputLabel>
									<Select
										value={collection}
										name='collection'
										label='Collection'
										onChange={this.handleChange}
									>
										<MenuItem value='letters'>Letters</MenuItem>
										<MenuItem value='unreleasedLetters'>
											Unreleased Letters
										</MenuItem>
										<MenuItem value='posts'>Posts</MenuItem>
										<MenuItem value='unreleasedPosts'>
											Unreleased Posts
										</MenuItem>
										<MenuItem value='followers'>Followers</MenuItem>
									</Select>
								</FormControl>
								{collection === 'followers' && (
									<div>
										<label className='inputText mgt'>Field</label>
										<FormControl fullWidth>
											<Select
												value={field}
												name='field'
												onChange={this.handleChange}
											>
												<MenuItem value='followingCount'>
													Following Count
												</MenuItem>
												<MenuItem value='followerCount'>
													Follower Count
												</MenuItem>
											</Select>
										</FormControl>
									</div>
								)}
								{collection !== '' && (
									<CustomButton
										btnText={t('update')}
										onClick={this.updateUserFields}
										variant='contained'
										id='customCancelButton'
										startIcon={<AccountCircleRoundedIcon />}
									/>
								)}
								<hr className='mgt2' />
								<hr />
								<label className='inputText mgt'>
									Update Spammed and Disabled For
								</label>

								<FormControlLabel
									control={
										<Switch
											value='clearDisabled'
											handleSwitchChange={this.handleSwitch}
											checked={clearDisabled}
											name='clearDisabled'
										/>
									}
									label={clearDisabled ? 'Undo Disabled' : 'Leave/Set Disabled'}
									labelPlacement='bottom'
								/>
								<CustomAlerts
									error={setUserAdmin.disabledFor > new Date().toISOString()}
									severity='warning'
									icon={<AccountCircleRoundedIcon />}
									message={
										<TimerCountdown
											countdownTimer
											text='Disabled for: '
											releaseDate={setUserAdmin.disabledFor}
											selectorId={'adminTimer'}
										/>
									}
									margin='1rem 0 0 0'
								/>
								<label className='inputText mgt mgb3px'>Spammed Count</label>
								<FormControl fullWidth>
									<Select
										value={spammedCount}
										name='spammedCount'
										onChange={this.handleChange}
									>
										<MenuItem value={0}>0</MenuItem>
										<MenuItem value={1} disabled>
											1 (couple of hours - disabled for now since cloud function
											is set to query db once a day)
										</MenuItem>
										<MenuItem value={2}>2 (disable 24hs)</MenuItem>
										<MenuItem value={3}>3 (disable 7 days)</MenuItem>
										<MenuItem value={4}>4 (disable 30 days)</MenuItem>
										<MenuItem value={5}>5 (disable 5 months)</MenuItem>
										<MenuItem value={6}>6 (disable a year)</MenuItem>
										<MenuItem value={7}>7 (disable 2 years)</MenuItem>
										<MenuItem value={8}>8 (disable 3 years)</MenuItem>
									</Select>
								</FormControl>
								<label className='inputText mgt'>
									User can login{' '}
									{spammedCount === 0
										? 'now.'
										: dayjs(disabledFor).fromNow() + '.'}
								</label>
								<ConfirmationDialog
									open={disabledForConfirmationDialog}
									openIconOrString={t('update')}
									idOpenIconOrString='customCancelButton'
									customButtonId='customInfoButton'
									closeFunc={() =>
										this.handleConfirmationDialog(false, 'disabledFor')
									}
									openFunc={() =>
										this.handleConfirmationDialog(true, 'disabledFor')
									}
									actionBtnText='SET'
									action={this.updateUser}
									phrase='Spammed Count'
									initialBtnIcon={<AccountCircleRoundedIcon />}
									startIcon={<AccountCircleRoundedIcon />}
									contentTitle={`@${setUserAdmin.handle} will be able to login
									${spammedCount === 0 ? 'now.' : dayjs(disabledFor).fromNow() + '.'}`}
									phrase4='Are you sure?'
								/>

								<hr />
								<hr className='mgb' />
								<div className='flex'>
									<label className='inputText'>
										Revoke Refresh Token (require user to login again in a short
										period of time)
									</label>

									<CustomPopover
										dialog
										content={
											<div className='pdX minFont'>
												<p className='mgtH'>
													<u>Access Tokens and Refresh Tokens:</u>
												</p>
												<ul>
													However, revoking sessions doesn't log out
													unauthorized users instantly. Instead, the
													unauthorized user will need to login again shortly
													after the token revocation.
													<li>
														When a user logs in, they receive an access token
														and a refresh token.
													</li>
													<li>
														The access token, typically lasting one hour,
														authenticates their requests to the servers.
													</li>
													<li>
														A refresh token, with a longer lifespan, fetches a
														new access token without needing a new login.
													</li>
												</ul>
												<p className='mgtH'>
													<u>Revoking Sessions:</u>
												</p>
												<ul>
													<li>
														When you revoke all sessions, it immediately
														terminates any active refresh tokens associated with
														the user's account.
													</li>
													<li>
														However, revoking sessions doesn't immediately log
														out the user.
													</li>
													<li>
														The user will be required to login again shortly
														after the token is revoked.
													</li>
												</ul>
											</div>
										}
									/>
								</div>

								<ConfirmationDialog
									open={revokeConfirmationDialog}
									openIconOrString='REVOKE REFRESH TOKEN'
									idOpenIconOrString='customCancelButton'
									customButtonId='customCancelButton'
									closeFunc={() =>
										this.handleConfirmationDialog(false, 'revokeToken')
									}
									openFunc={() =>
										this.handleConfirmationDialog(true, 'revokeToken')
									}
									actionBtnText='revoke'
									action={() => this.revoke('token')}
									phrase='Are you sure?'
									phrase2={`${setUserAdmin.handle} will have to reathenticate when the access token expires since the refresh token won't issue new tokens.`}
								/>
								<hr />
								<hr className='mgb2' />
								<label className='inputText mgt'>Logout All Sessions</label>
								<ConfirmationDialog
									open={logoutConfirmationDialog}
									openIconOrString='Log Out All Sessions'
									idOpenIconOrString='customCancelButton'
									customButtonId='customCancelButton'
									closeFunc={() =>
										this.handleConfirmationDialog(false, 'logoutAllSessions')
									}
									openFunc={() =>
										this.handleConfirmationDialog(true, 'logoutAllSessions')
									}
									actionBtnText='Log Out'
									action={() => this.revoke('account')}
									phrase='Are you sure?'
									contentTitle={`@${setUserAdmin.handle} will be logged out in all devices.`}
								/>

								<hr />
								<hr />
								<div className='flex spaceBetween '>
									{setUserAdmin.disabled ? (
										<h2>Unrestrict - Unban Account</h2>
									) : (
										<h2>Restrict - Ban User Id</h2>
									)}
									{setUserAdmin.userId && (
										<div className='flex spaceAround alignItemsCenter'>
											<DottedMenu
												dataHandle={setUserAdmin.handle}
												dataUserId={setUserAdmin.userId}
												disabled={setUserAdmin.disabled}
												profile={true}
												infringements={setUserAdmin.disabled}
												appealable={setUserAdmin.appealable}
											/>
										</div>
									)}
								</div>
								<hr />
								<hr className='mgb2' />
								<div className='updateUid'>
									<label className='inputText mgt'>SET NEW EMAIL FOR</label>
									<CopyText text={setUserAdmin.email} noMargin />
									<p>
										<u>User Authentication UID:</u> {setUserAdmin.userId}
									</p>
									<CustomInput
										variant='outlined'
										type='email'
										titleLabel='New Email'
										placeholder='Type here the new email address'
										name='email'
										inputId='email'
										autoComplete='email'
										onChange={this.handleChangeMail}
										helperText={
											emailErrors.email && 'Check the information above.'
										}
										inputError={emailErrors.email ? true : false}
										inputValue={email}
										inputAdornmentPosition='end'
										required={true}
										maxLength={320}
										fullWidth={true}
										deletable={() => this.setState({ email: '' })}
										isEmail={isEmail(email)}
									/>
									<br />
									<FormControl fullWidth>
										<InputLabel id='demo-simple-select-label'>
											Email Verified
										</InputLabel>
										<Select
											value={emailVerified}
											name='emailVerified'
											label='Email Verified'
											onChange={this.handleChange}
										>
											<MenuItem value={'true'}>True</MenuItem>
											<MenuItem value='false'>False</MenuItem>
										</Select>
									</FormControl>

									{(email !== '' || emailVerified !== '') && (
										<CustomButton
											btnText='Update UID'
											onClick={this.updateUID}
											variant='contained'
											id='customDangerButton'
											startIcon={<AccountCircleRoundedIcon />}
										/>
									)}
								</div>

								{this.props.user.credentials.userId ===
									'6qF5GMirSwMjxNesFhFuZh9Lks13' && (
									<div>
										<hr className='mgt2' />
										<hr />
										<div className='flex spaceBetween '>
											{setUserAdmin.admin ? (
												<h2>Remove Admin</h2>
											) : (
												<h2>Make Admin</h2>
											)}
											{setUserAdmin.userId && (
												<div className='flex spaceAround alignItemsCenter'>
													<ConfirmationDialog
														open={setAdminConfirmationDialog}
														openIconOrString={t('update')}
														idOpenIconOrString='customCancelButton'
														customButtonId='customInfoButton'
														closeFunc={() =>
															this.handleConfirmationDialog(false, 'setAdmin')
														}
														openFunc={() =>
															this.handleConfirmationDialog(true, 'setAdmin')
														}
														actionBtnText='SET'
														action={this.setAdmin}
														phrase='ADMIN'
														phrase2='Are you sure?'
														initialBtnIcon={<AdminPanelSettingsIcon />}
														startIcon={<AdminPanelSettingsIcon />}
														contentTitle={`@${setUserAdmin.handle} ${
															setUserAdmin.admin
																? 'will not be an admin anymore.'
																: 'will become an admin.'
														}`}
													/>
												</div>
											)}
										</div>
										<hr />
										<hr className='mgb2' />
									</div>
								)}
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

AdminActions.propTypes = {
	user: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
	setAdminUserListener: PropTypes.func.isRequired,
	isEmailVerifiedAdmin: PropTypes.func.isRequired,
	setSpammedUserAdmin: PropTypes.func.isRequired,
	updateUserUid: PropTypes.func.isRequired,
	adminResetImage: PropTypes.func.isRequired,
	setAdminPriviledge: PropTypes.func.isRequired,
	reduceUserDetailsAdmin: PropTypes.func.isRequired,
	revokeAllTokenSessionsAdmin: PropTypes.func.isRequired,
	logoutAllSessionsAdmin: PropTypes.func.isRequired,
	getAccountReports: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	user: state.user,
	UI: state.UI,
	data: state.data,
});

const mapActionsToProps = {
	setAdminUserListener,
	isEmailVerifiedAdmin,
	setSpammedUserAdmin,
	updateUserFields,
	updateUserUid,
	revokeAllTokenSessionsAdmin,
	logoutAllSessionsAdmin,
	adminResetImage,
	setAdminPriviledge,
	reduceUserDetailsAdmin,
	getAccountReports,
};

export default connect(mapStateToProps, mapActionsToProps)(AdminActions);
